<template>
  <div>
    <a-card title="更新日志">
      <div>
        <div class="title">2024-04-08</div>
        <div class="content">
          <div>1. 增加更新日志功能</div>
          <div>2. 增加整库位移库功能</div>
          <div>3. 增加库存预警提示功能</div>
          <div>4. 优化库位平面图</div>
          <div>5. 增加仓库数据可视化大屏</div>
          <div>6. 增加首页看板配置功能</div>
        </div>
        <!-- <a-divider /> -->
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
}

.content {
  margin-top: 12px;
  padding: 0 24px;
  font-size: 16px;
}
</style>
